export default{
    common:{
        userName:'安徽创瑞',
        ok: '确定',
        cancel: '取消',
        yes: '是',
        no: '否',
        export: '导出',
        checkDetail: '查看详情',
        emailCustomerID: '邮箱/客户ID',
        pwd: '密码',
        login: '登录',
        fogetPwd: '忘记密码',
        emailCollectPwd: '邮箱找回密码',
        email : '邮箱',
        emailNotCorrect: '邮箱格式不正确',
        OTP: '验证码',
        plsEnterOTP: '请输入验证码',
        plsEnterEmail: '请输入邮箱',
        plsEnterEmailOTP: '请输入邮箱验证码',
        retrieveOTP: '获取验证码',
        newPwd: '新密码',
        plsEnterNewPwd: '请输入新密码',
        pwdFormatNotCorrect: '密码格式不正确',
        findPwd: '找回密码',
        returnLogin: '返回登录',
        readAndAgree: '我已阅读并同意',
        setting: '设置中心',
        accountDetails: '账户信息',
        certifiedDetails: '认证信息',
        companyName: '公司名称',
        certifiedEntity: '认证主体',
        accountDetails: '账号信息',
        userAccount: '用户账号',
        emailTmp: '邮箱',
        topUpCenter: '充值中心',
        bill: '账单',
        invoiceDetail: '账单信息',
        billingTimeZone: '账单时区',
        billingCycle: '账单周期',
        settlementCurrency: '结算币种',
        balance: '余额',
        balanceAlert: '余额提醒',
        creditLimits: '信控额度',
        creditAlert: '额度提醒',
        noticeEmail: '通知邮箱',
        Save: '保存',
        privacyAgreement: '隐私协议',
        logout: '退出登录',
        internationalSMS: '国际短信',
        preview: '数据预览',
        sentStatistics: '发送统计',
        volSubmitted: '提交量',
        volSucceed: '成功量',
        volFailed: '失败量',
        unknown: '未知量',
        volError: '无效量',
        otherStatistics: '其他统计',
        expenseStatistics: '费用统计',
        checkDetails: '查看详细数据',
        application: '应用',
        countryRegion: '国家/地区',
        countryRegionTmp: '国家地区',
        plsSelectCountryRegion: '请选择国家/地区',
        plsSelectOperator: '请选择运营商',
        qty: '数量',
        used: '消耗',
        totalUsed: '共消耗',
        topExpenseStatistics: 'TOP1—10国家/地区费用统计',
        submittedStatistics: '提交统计',
        totalSubmitted: '共提交（条）',
        appliedAccount: '应用账号',
        plsEnterApplicationID: '请输入应用ID',
        plsEnterApplicationName: '请输入应用名称',
        plsEnterApplicationType: '请输入应用类别',
        plsSelectApplication: '请选择应用',
        applicationID: '应用ID',
        applicationName: '应用名称',
        applicationType: '应用类别',
        otp: '验证码',
        mkt: '营销',
        account: '账号',
        pwd: '密码',
        status: '状态',
        off: '停用',
        on: '启用',
        timeCreated: '创建时间',
        onlineDelivering: '在线发送',
        plsSelectApplicationAccount: '请选择应用账号',
        content: '短信内容',
        phoneNumber: '号码',
        addManually: '手动添加',
        importBatch: '批量导入',
        uploadFile: '上传文件',
        downloadTemplate: '下载模板',
        supportFileFormat: '支持xls，xlsx文件格式',
        fileMaximum: '格式要求文件大小不超过500M',
        allNumbers: '所有号码',
        clickEmpty: '一键清空',
        walidNumber: '有效号码',
        invalidNumber: '无效号码',
        duplicatedNumber: '重复号码',
        check: '查看',
        country: '国家',
        submitSchedule: '定时提交',
        plsSelect: '请选择',
        plsSelectSubmitTime: '请选择提交时间',
        telephoneNumber: '电话号码',
        plsEnterPhoneNumber: '请输入电话号码',
        countryCode: '国码',
        sendSubmission: '提交发送',
        sending: '立即发送',
        batchRecord: '批次记录',
        plsEnterSender: '请输入Sender',
        batchNo: '批次号',
        application: '应用',
        SMSContent: '短信内容',
        plsEnterSMSContent: '请输入短信内容',
        submitSMS: '提交短信',
        noCounted: '计费条数',
        SMSOriginated: '短信来源',
        submitTime: '提交时间',
        action: '操作',
        singleRecord: '单条记录',
        operator: '运营商',
        submitTime: '提交时间',
        deliverTime: '发送时间',
        notification: '消息',
        setting: '设置',
        exit: '退出',
        sendingRecords: '发送记录',
        startDate: "开始时间",
        endDate: "结束时间",
        dataOverview: '数据概览',
        inquiry: '查询',
        countriesTrafficStatistics: 'TOP1-10国家/地区发送统计',
        submissionStatistics: '提交量统计',
        userProtocol: '用户协议',
        alreadyReadPrivacyProtocol: '您已阅读确定隐私协议！',
        loadingFailed: '加载失败',
        systemAbnormal: '系统异常',
        applicationAccount: '应用账户',
        sendingTmp: '拼命发送中...',
        notActivated: ' 未激活',
        plsEnterSenderID: '请输入Sender ID（选填）',
        regularSMS: '常规短信',
        variableSMS: '变量短信',
        importVariables: '导入变量',
        used: '消耗',
        SMS: '条短信',
        charactersNumber: '发送内容建议少于350个字数，最多不超过500个字',
        referenceContent: '（仅作参考，实际编码及计费以完整内容为主）',
        validNumbersOnly: '仅针对有效号码发送，无效号码、重复号码不支持发送。',
        purged: '已清除',
        SMSLengthChargingStardard: '短信长度及计费标准',
        countriesReached: '发送国家',
        support: '支持',
        unsubscribeReturnT: '回T退订',
        plsAddValidNumber: '请添加有效的号码',
        senderIDMinimum3Digits: 'SenderId 最少为3位数字',
        senderIdFormatError: 'SenderId格式错误， 由3-11位字符',
        senderIdCannotContainChinese: 'SenderId不能包含汉字',
        plsFillSMSContent: '请填写短信内容',
        plsSelectScheduledTimeSend: '请选择定时发送时间',
        scheduledTimeTips: '定时时间不能小于当前时间,并且需要大于当前时间五分钟以上',
        // : '本次共计发送有效号码XX 个，消耗XX条短信—This batch consists a total of XX valid numbers, consuming XX SMS',
        // : '本次共计发送有效号码XX 个，消耗XX条短信—This batch consists a total of XX valid numbers, consuming XX SMS',
        confirmSending: '确定发送',
        cancelSending: '取消发送',
        prompt: '提示',
        insufficientBalance: '余额不足！',
        sendSucceedContinue: '发送成功，是否继续提交？',
        continueSend: '继续发送',
        checkRecord: '查看记录',
        submitFailed: '发送失败',
        submitAbnormal: '提交异常',
        submitSucceed: '发送成功',
        abnormalAction: '操作异常',
        submittingRequestExit: '正在提交发送请求，确定离开吗？',
        scheduledSubmission: '定时发送',
        submissionRecord: '发送记录',
        plsEnterApplicationID : '请输入应用ID/应用名称',
        submittedTimeScheduledTime: '提交时间/定时时间',
        cancelSending: '取消发送',
        cancelled: '已取消',
        sent: '已发送',
        confirmExportBatchDetails: '是否确认导出该批次详情?',
        confirmCancelSending: '确定要取消发送吗？',
        cancelSucceed: '取消成功',
        submittedScheduledSendTime: '提交时间/定时时间/发送时间',
        submitSendingTime: '提交时间/发送时间',
        submittedStatus: '提交状态',
        receivingStatus: '接收状态',
        termsConditionsStatement: '条款声明',
        accountBalanceAvailable: '表示当前账户可用剩余余额',
        maximumOverdraftCreditLimits: '表示余额为0时，最大可透支的信用额度及剩余信用额度',
        confirmExit: '确定退出系统吗?',
        disclaimer: '免责声明',
        informationSecurityCertificates: '信息安全责任证书',
        returnUpperLevel: '返回上一级',
        passed: '已通过',
        invoiceBalance: '账单余额',
        accountAmountLessThan: '账户金额低于',
        warningAlert: '预警提示',
        plsEnterAmount: '请输入金额',
        creditLimitBelow: '信控额度低于',
        warningTips: '预警提示',
        emailCannotBlank: `通知邮箱不能为空！`,
        balanceSetupSucceed: '余额设置成功！',
        submittedTotal: '共提交',
        succeedTotal: '共成功',
        failedTotal: '共失败',
        unknownTotal: '共未知',
        invalidTotal: '共无效',
        DRTotal: '总DR',
        welcometo : '欢迎来到 ',
        emailCustomerIDPhoneNumber: '邮箱/客户ID/手机号码',
        plsEnterEmailCustomerIDPhoneNumber: '请输入邮箱/客户ID/手机号码',
        plsEnterEmailCustomerID: '请输入邮箱/客户ID',
        pwdOTP: '密码/验证码',
        plsEnterPwdOTP: '请输入密码/验证码',
        plsEnterPwd: '请输入密码',
        forgottenPwd: '忘记密码？',
        plsEnterEmailCustomerID: '请输入邮箱/客户ID',
        charactersLength: '长度在 3 到 50 个字符',
        plsEnterLoginPwd: '请输入登录密码',
        loginSuccess: '登录成功！',
        loginFailed: '登录失败',
        fileUploadSucceed: '文件上传成功',
        fileUploadFailed: '文件上传失败',
        downloadTemplate: '是否下载模板?',
        plsUploadFile: '请上传文件!',
        uploadFileSize: '上传文件大小不能超过 500MB!',
        uploadFileFormatIncorrect: '上传文件格式不正确!',
        amendSucceed: '修改成功',
        amendFailed: '修改失败',
        phoneTips: '请输入电话号码，多个号码以“,”隔开，电话号码需添加上区号，例如: 8613800000000,8615800000000',
        detectedSuggest: `检测到您当前输入的数量比较大，建议您使用“批量导入”功能`,
        phoneNumberAbnormal: '检测手机号异常',
        loginTimeoutRetry: '登录超时，请重新登录',
        resending: '重新发送',
        pwdTips: '支持字母、数字、特殊字符组成且长度在8~16位的密码',
        plsEnterCorrectEmail: '请输入正确的邮箱号',
        OTPSentPlsCheck: '验证码发送成功，请注意查收',
        OTPFailed: '验证码发送失败',
        plsEnterCorrectEmailAddress: '请输入正确的邮箱',
        pwdChangeSucceedLoginAgain: '修改成功，请重新登录',
        submitting: '提交中',
        lengthBetweenCharacter: '长度在 4 到 6 个字符',
        billOverview: '账单总览',
        product: '产品',
        MOCharge: '上行计费',
        initialCost: '初装费',
        rentalCost: '月租费',
        chargedSMS: '计费条数',
        amountUsed: '消耗金额',
        invoiceDetail: '详细账单数据',
        totalVolume: '汇总量',
        unitPrice: '单价',
        amount: '金额',
        total: '合计',
        invoiceNo: '账单单号',
        invoiceName: '账单名称',
        customerPaymentType: '客户付款类型',
        billingTimeZone: '账单时区',
        cycle: '周期',
        billingStatus: '账单状态',
        billingAmount: '账单金额',
        discAmount: '优惠金额',
        finalAmount: '优惠后金额',
        amountIncludedTAX: '含税金额',
        finalInvoiceAmount: '最终账单金额',
        previewInvoice: '账单预览',
        product: '产品',
        MOCharge: '上行计费',
        initialCost: '初装费',
        rentalCost: '月租费',
        chargedSMS: '计费条数',
        amountUsed: '消耗金额',
        invoiceDetail: '详细账单数据',
        totalVolume: '汇总量',
        unitPrice: '单价',
        amount: '金额',
        total: '合计',
        export: '确定导出',
        adjustInvoice: '调整账单',
        settlementCurrency: '结算币种',
        pleaseSelectCurrency: '请选择结算币种',
        invoiceAmount: '账单金额',
        discount: '是否优惠',
        cashDisc: '现金优惠',
        discOnDisc: '折扣优惠',
        taxRate: '税率',
        amountIncludedTax: '含税金额',
        offsetInvoice: '核销账单',
        invoiceDetailTmp: '账单信息',
        writeOffAmount: '已核销金额',
        linkedTopUpBill : '已关联充值单',
        linkedTopUpBillTmp: '关联充值单',
        addTopUpBill: '添加充值单',
        topUpBill: '充值单',
        plsSelectTopUpBill : '请选择充值单',
        topUpBillAmount: '充值单金额',
        amountToBeWrittenOff: '待核销金额',
        amountToBeWriteOff: '需核销金额',
        plsEnterOffsetAmount: '请输入核销金额',
        clickToFill: '一键带入',
        writtenOff: '已核销',
        toBeOffset: '未核销',
        newCustomerBill: '新增客户账单',
        checkOffsetRecord: '查看核销记录',
        billOverview: '账单总览',
        confirmExportBill: '是否导出账单?',
        amountTips: '总核销金额不可大于可核销的账单金额',
        offsetBillSucceed: '核销账单成功',
        discountMethod: '折扣方式',
        cashOff: '优惠现金',
        plsEnterOffAmount: '请输入优惠金额',
        offDiscount: '优惠折扣',
        plsEnterOffDiscount: '请输入优惠折扣',
        plsEnterTaxRate: '请输入税率',
        plsEnterFinalBillAmount: '请输入最终账单金额',
        adjustBillSucceed: '调整账单成功',
        discount: '折',


        SMSLengthStandards: '短信长度及计费标准',
        SMSp1: `- 根据短信的编码，每条消息占用的短信数量将与文本长度成正比：`,
        SMSp2: `- 对于 GMS7 消息：`,
        SMSp3: `- 如果消息的总长度小于或等于到 160 个字符，那么第一个也是唯一的消息部分可以容纳 160 个字符。`,
        SMSp4: `- 如果总长度超过 160 个字符，则每个消息部分可以包含 153 个字符（由于需要额外的数据空间来连接目标手机上的 SMS，因此可以将更少的字符放入一个部分）`,
        SMSp5: `- 对于 Unicode 消息：`,
        SMSp6: `- 如果消息的总长度小于或等于 70 个字符，则第一个也是唯一的消息部分可以容纳 70 个字符。`,
        SMSp7: `- 如果总长度超过 70 个字符，则每个消息部分可以包含 67 个字符（由于需要额外的数据空间来连接目标手机上的 SMS，因此可以将更少的字符放入一个部分）`,
        SMSp8: `- 对于 ASCII 消息：`,
        SMSp9: `- 如果消息的总长度小于或等于 140 个字符，则第一个也是唯一的消息部分可以容纳 140 个字符。`,
        SMSp10: `- 如果总长度超过 140 个字符，则每个消息部分可以包含 134 个字符（由于需要额外的数据空间来连接目标手机上的 SMS，因此可以将更少的字符放入一个部分）`,
        SMSp11: `- 如果您发送带有长度等于消息的 x 部分，您将支付 x 条短信的费用。`,
        palphaDisclaimer: '{name}免责声明',
        paPrivacyPolicy: '{name}隐私协议',
        ppH1: '1. 定义',
        ppH1_1: '协议及本《条款和条件》中包含的以粗体显示的术语已给出定义，具有本条中所载明的含义：',
        ppH1_2: '协议：由 {name} 与客户签订的每份协议和/或订单，本《条款和条件》应适用于这些协议和/或订单。',
        ppH1_3: '适用的数据保护法律：适用于 {name} 和/或客户处理个人数据的所有适用法律和法规。',
        ppH1_4: '适用法律：客户访问和使用服务所在的任何司法管辖区内当时有效的国家、地方或其他法律、规则、法规、可执行的监管指导、命令、判决、法令或裁决，包括但不限于数据保护和隐私法规、指南、条件和政策规则，以及/或者适用于服务和/或最终用户服务的运营商的法规、指南和条件。',
        ppH1_5: '客户：{name} 与之签订协议的一方。',
        ppH1_6: '客户材料：由客户或代表客户向 {name} 提供的任何形式或媒介的所有信息、数据、内容和其他材料。',
        ppH1_7: '{name}：适用订单中确定的与客户签订协议的 {name} 公司。',
        ppH1_8: '{name} 知识产权：服务、软件、{1} 平台以及前述各项的所有改进、修改、增强或衍生作品，以及前述任何一项的所有知识产权。',
        ppH1_9: '{name} 平台：{1} 的计算环境，旨在提供服务，并创建运营商网络和客户系统之间的连接。',
        ppH1_10: 'DPA：可在 [网站] 上获取的《数据处理附录》，该附录构成本《条款和条件》以及客户与 {name} 就客户使用服务订立的协议的组成部分。',
        ppH1_11: '知识产权：全球范围内任何性质的任何及所有知识产权，在该等知识产权的整个保护期限内有效，包括所有：(a) 专利、注册商标、服务标志、版权、外观设计以及上述任何内容的任何和所有注册申请；(b) 未注册商标、服务标志、外观设计、设计权和版权；以及 (c) 无论以何种方式产生的专门知识、商业秘密，以及前述各项的任何权利或权益。',
        ppH1_12: '生效日期：指特定协议生效的日期。',
        ppH1_13: '最终用户：客户授权使用客户最终用户服务的自然人或实体。',
        ppH1_14: '最终用户服务：客户向其最终用户提供的服务。',
        ppH1_15: '运营商：向其客户（包括 {name}）、订阅最终用户和其他运营商提供（无线）消息、语音和数据通信及其他相关服务的（移动）电子通信服务提供商或过顶 (OTT) 通信服务的提供商。 订单：指协议。',
        ppH1_16: '个人数据：与已识别或可识别的自然人（“数据主体”）相关的任何信息；可识别的自然人是指可直接或间接识别的自然人，尤其是通过提及诸如姓名、识别号码、位置数据、在线身份标识信息或特定于该自然人的身体、生理、遗传、心理、经济、文化或社会身份的一个或多个因素的标识符识别。',
        ppH1_17: '服务：{name} 依据协议向客户提供的服务，其中可能包括电子通信、专业服务、作为软件即服务提供应用程序的访问权限、提供两种平台服务的服务，包括对 {name} 平台的访问和使用、任何应用程序编程接口 ({name} API) 和/或与 {name} 平台或 {name} API 相关的通信服务。',
        ppH1_18: '流量：传输自和传输到移动电话和/或固定线路、移动设备或在线应用程序的电子通信和/或数据流量，包括但不限于进行交换的任何数据。流量包括但不限于 SMS（短消息服务）、MMS（多媒体短信）、Push（推送消息）、OTT、RCS（融合通信）、语音和/或数据。',
        ppH1_19: '工作日：根据提供服务的 {name} 实体所在的时区，星期一至星期五上午 8:30 至下午 5:00，不包括一般公认的公共节假日。', 
        ppH2: '2. 范围',
        ppH2_1: '2.1 本《条款和条件》适用于 {name} 和客户之间的所有协议，除非双方另有明确书面约定。',
        ppH2_2: '2.2 客户所使用的任何一般（购买）条款和条件，或协议及本《条款和条件》所载内容以外的任何其他条款和条件的适用性特此明确排除。',
        ppH2_3: '2.3 双方特此同意，在法律所允许的最大范围内，任何电子签名应与手写的签名具有相同的法律效力和可执行性。就本《条款和条件》而言，凡提及“书面”或“以书面形式”之处，均指任何形式的书面通信，包括但不限于电子签名，且任何此类书面通信均可通过电子传输方式传输。',
        ppH2_4: '2.4 适用法律适用于客户在交付最终用户服务所在的国家/地区向最终用户提供的最终用户服务。客户应熟悉适用于最终用户服务的此类适用法律（包括在需要时寻求独立的法律建议）。{name} 对此不提供任何保证，也不对此负责。',
        ppH2_5: '2.5. 服务根据 {name}《服务水平协议》（“SLA”，发布于通过引用明确纳入本《条款和条件》。',
        ppH2_6: '2.6. 公布于 [网站] 的 DPA、服务专用条款、国家/地区专用条款以及管辖法律和争议条款构成本《条款和条件》以及客户与 {name} 就客户使用服务订立的协议的组成部分。',
        ppH2_7: '2.7 如果构成协议的各种文件之间存在任何冲突或不一致，以下优先顺序（先提到的文件优先于后提到的文件）应适用：(i) 协议， (ii) 本《条款和条件》，以及 (iii) 任何前述文件中包含的或引用的任何其他文件。',
        ppH3: '3. 价格、付款、税费和争议',
        ppH3_1: '3.1 客户应支付协议上列出的所有费用。费用应以协议中规定的货币支付。费用不包括增值税 (VAT) 和/或任何政府机构征收的任何其他税费、收费或费用。',
        ppH3_2: '3.2 客户应对 {name} 平台收发的所有流量支付费用。适用的流量费载于 {name} 网站上。{name} 保留更改这些内容的权利，恕不另行通知。{name} 平台的计费期以 CE(S)T［欧洲中部时间（欧洲中部夏令时间）］时区为基础。费用的计算应参考 {name} 的记录，而不是参考客户的任何记录。{name} 始终有权根据适用法律的变更和/或运营商实施的费率变更来更改其流量费。',
        ppH3_3: '3.3 流量费不包括可能适用于特定目的地的任何适用的政府、监管或运营商费或附加费。除流量费外，客户还应支付所有此类附加费。',
        ppH3_4: '3.4 {name} 有权根据去年消费者物价指数 (CPI) 的变化每年对费用进行指数化。{name} 将通过简单通知和/或在向客户发出的账单上注明来告知指数化信息。',
        ppH3_5: '3.5 客户账户的标准信用额度为二千美元 (＄2.000)。当客户达到 75% 的信用额度时，{name} 将向客户发送电子通知（电子邮件）。',
        ppH3_6: '3.6 在收到信用额度通知后，客户将能够支付 {name} 尚未收到付款的未结账单。到期及时付款将防止客户账户在达到信用额度时被（临时）冻结。客户可请求提高适用的信用额度。客户特此同意：{name} 可自行决定随时经电子邮件通知客户修改（增加或减少）或取消信用额度，该等修改或取消即时生效。请求更改信用额度必须经 {name} 书面批准。在提高信用额度或客户支付账单之前，{name} 有权暂停服务，无需另行通知，且/或无需承担任何责任。在收到款项和/或提高额度之前，{name} 无义务提供任何服务。',
        ppH3_7: '3.7 客户应在账单日期后十四 (14) 天内支付所有账单金额，不得暂缓支付、抵销或扣除，但协议明确包含不同付款条款的除外。',
        ppH3_8: '3.8 客户未能在约定付款期限内向 {name} 支付到期金额的，应对未结账单金额支付利息，金额为适用法律允许的最高金额，无需由 {name} 另行通知违约。客户将补偿 {name} 在收取任何逾期付款或利息过程中所产生的所有合理费用和开支（包括但不限于合理的律师费）。',
        ppH3_9: '3.9 对于国际、非单一美元支付区 (SEPA) 的交易，处理交易的银行可能会收取银行业务费。在该等情况下，客户应承担在根据协议付款时在账单上注明的、其银行、任何中间行和 {name} 的银行收取的费用。{name} 收到的净金额应与账单上的金额一致。为此，客户仍有责任支付任何差额。',
        ppH3_10: '3.10 {name} 有权以客户欠 {name} 的款项抵销 {name} 欠客户的款项。',
        ppH3_11: '3.11 客户可以在适用于账单的付款期限内以书面方式真诚地对账单提出异议，前提是客户及时支付账单中任何无争议的部分。',
        ppH3_12: '3.12 客户有预付账户的，使用特定服务时向其收取的费用应从该预付账户余额中扣除。只有向客户的预付账户中预付了相应的最低金额后，方可使用需要收取该等费用的服务。',
        ppH3_13: '3.13 客户可在 {name} 收到款项的次日使用预付存款和余额。预付存款不可退还，并将在客户购买预付余额之日起十二 (12) 个月到期。',
        ppH3_14: '3.14 如果使用信用卡支付的款项被退回，则客户构成违约。在这种情况下，{name} 有权将退款金额与余额相抵。若无余额，{name} 有权终止客户的预付账户。',
        ppH3_15: '3.15 客户将赔偿 {name} 第三方滥用的预付余额。',
        ppH3_16: '3.16 {name} 有权拒绝客户注册预付账户，并以任何理由终止现有账户。如果在注册后三 (3) 个月内或所有信用额度使用完毕后三 (3) 个月内未补充账户余额，则预付账户将被注销。',
        ppH4: '4.义务',
        ppH4_1: '4.1 客户应根据书面请求，对执法机关、监管机构或运营商的信息请求或 {name} 的信息请求予以合理配合，以履行其法律义务。',
        ppH4_2: '4.2 客户应确保对 {name} 平台和服务的客户账户的访问仅限于客户授权人员，且登录凭据应予以安全存储。客户负责针对 {name} 平台和服务上的客户账户的访问权限，包括但不限于其账户上发生的所有活动，即使未经客户授权，也包括通过客户账户发送/交付的所有流量、流量的合法性以及与最终用户的法律关系，其中包括最终用户是否提供了接收流量的有效同意。客户保证最终用户服务遵守适用（数据保护）法律，且不会侵犯任何第三方权利。客户将维护最新的最终用户同意记录。客户不得发送任何垃圾邮件、未经请求的流量，亦不得发送其他不道德、非法、应受惩罚或以其他方式欺诈或非法的流量或内容，同时，不得违反 {name} 的反垃圾邮件政策。',
        ppH4_3: '4.3 客户应仅将服务用于其预期和正常的目的和/或协议中商定和所述的目的。在不受限制的情况下，客户不得：(i) 对服务的任何部分进行逆向工程或以其他方式试图未经授权访问；(ii) 转售服务或以其他方式使用服务或允许任何人将服务用于协议规定的客户利益以外的任何目的；(iii) 干扰或（试图）破坏服务的完整性或提供，或其中包含或传输的任何数据或内容；(iv) 通过服务收集或收集关于服务的数据，但 {name} 提供的明确用于此类目的的服务功能除外；或 (v) 使用服务或任何 {name} 机密信息对竞争性或相关产品或服务进行基准对照或竞争分析，或研发、商业化、许可或销售可能直接或间接与服务展开竞争的任何产品、服务或技术。',
        ppH5: '5. 保证和赔偿',
        ppH5_1: '5.1 客户声明并保证 (i) 其已获得关于访问和使用客户材料的所有必要的权利、授权和许可；(ii) {name} 根据协议使用客户材料将不会违反任何适用（数据保护）法律或导致违反客户与任何第三方之间的任何协议或义务；且 (iii) 推广最终用户服务将不会侵犯或违反任何第三方的任何知识产权。',
        ppH5_2: '5.2 除非根据适用法律规定不可执行，尽管有第 8 条规定且不受任何限制，对于由于以下事项引起、第三方针对 {name} 提起的任何索赔、诉讼或法律程序而致使 {name} 遭受的所有损失、损害、责任和费用（包括合理的律师费）（下称“损失”），客户将赔偿 {name}、为其抗辩并使其免受上述损失之损害：(i) 客户使用服务、最终用户服务或客户材料，包括但不限于：任何声称客户使用服务、最终用户服务或客户材料侵犯、盗用或以其他方式违反了任何第三方的知识产权、隐私或其他权利的主张；(ii) 任何声称使用、提供、传输、显示或存储客户材料违反了适用（数据保护）法律的主张，以及/或 (iii) 客户以不符合协议的方式使用服务。{name} 将立即通知客户任何要求赔偿的索赔事项，并将合理地配合客户进行抗辩和/或和解。客户和 {name} 应尽一切合理努力协调其与此类索赔的抗辩相关的行动方案。',
        ppH6: '6. 保密和公开',
        ppH6_1: '6.1 在本《条款和条件》中，“机密信息”是指一方（“披露方”）就协议向另一方（“接收方”）提供的（无论是口头还是书面的）、鉴于信息的性质和/或披露情况，被指定机密或应合理视为具有机密性质的任何信息。为明确起见，服务将被视为 {name} 的机密信息。但是，凡是符合以下情形的信息或材料，均不属于机密信息：(i) 除因违反协议的保密条款外，在披露之日或之后被公众普遍知晓或可获得的信息或材料；(ii) 接收方在从披露方处获得之前，已正当知悉的信息或材料；(iii) 接收方从有权在不违反对披露方承担的任何保密义务的情况下披露此类信息或材料的第三方处正当获得的信息或材料；或 (iv) 未使用或接触披露方的任何机密信息，而由接收方或为接收方独立开发的信息或材料。在不限制上述规定的前提下，本《条款和条件》中的任何内容均不会限制或约束 {name} 使用或披露 {name} 或其人员在履行 {name} 在本《条款和条件》项下的义务时获得的任何一般专有知识、经验、概念和/或想法的能力。',
        ppH6_2: '6.2 接收方将对披露方的机密信息严格保密，除为履行和行使其在本《条款和条件》项下义务或权利所必要的情况外，不得使用披露方的机密信息。除下列情况外，接收方不得披露披露方的任何机密信息：(i) 向接收方的员工、代表或承包商披露，前提是该等员工、代表或承包商确实需要知悉该等机密信息，并且受包含至少与本《条款和条件》所载限制条款具有同等保护力度的使用和禁止披露限制条款的书面协议之约束，或 (ii) 根据法院、行政机构、证券市场或政府机构的命令或要求进行披露，但前提是接收方向披露方发出合理期限的事先书面通知，以便披露方寻求保护令或以其他方式就披露提出异议。',
        ppH6_3: '6.3 各方在机密信息方面的义务自生效日期起生效，并将在协议终止后满三 (3) 年时到期；但是，对于构成商业秘密的任何机密信息（根据适用法律确定），只要其仍受适用法律规定的商业秘密保护，保密义务将在协议终止后或到期后继续有效。',
        ppH6_4: '6.4 协议的条款和条件将构成各方的机密信息，但可为了尽职调查之目的，在保密基础上披露给一方的顾问、律师、实际或真实的潜在收购者、投资者或其他资金来源（及其各自的顾问和律师）。',
        ppH6_5: '6.5 客户特此向 {name} 授予在营销、销售、财务和公共关系材料以及其他通讯中使用客户的名称、商标和公司徽标的权利和许可，从而可将客户识别为 {name} 的客户。经 {name} 事先书面批准，{name} 特此授予客户一项明确的权利，即仅为表明 {name} 是客户的服务提供商之目的使用 {name} 的名称和徽标。客户使用 {name} 的名称和徽标所带来的所有商誉和声誉的提升均以 {name} 为独家受益人；而 {name} 使用客户的名称和徽标所带来的所有商誉和声誉的提升也以 {name} 为独家受益人。除本《条款和条件》明确说明外，未经另一方事先书面同意，任何一方不得使用另一方的名称、标志、徽标、URL 或规范。',
        ppH7: '7. 知识产权',
        ppH7_1: '7.1 在遵守本《条款和条件》项下明确授予的有限权利的前提下，双方明确保留对各方知识产权拥有的所有权利、所有权和权益。除本《条款和条件》中明确规定的以外，一方未在本《条款和条件》项下向另一方授予任何权利。',
        ppH8: '8. 责任',
        ppH8_1: '8.1 客户承认，访问互联网、电信网络和其他通信媒体将受制于与（包括但不限于）服务可用性、传输可靠性、授权、真实性和数据安全性有关的不确定因素。{name} 不保证服务现在或将来完全无错误、无漏洞或不间断。{name} 对由于超出其控制范围的情况而导致的任何停止、减速、服务中断、性能问题或任何其他问题概不负责，包括但不限于电话和/或电信传输和/或运营商网络的干扰、客户的硬件、系统、软件或流程的缺陷或限制、互联网或无线连接的缺陷或限制。如发生错误、漏洞或中断，{name} 应尽其合理努力，根据业界公认的适当做法恢复服务。',
        ppH8_2: '8.2 服务和 {name} 平台均“按原样”提供。{name} 未就 {name} 平台（包括服务或本《条款和条件》项下提供的任何其他服务）向客户或任何其他方作出任何保证或陈述。在适用法律允许的最大范围内，{name} 特此否认所有明示或默示的保证和陈述，包括但不限于有关适销性、适用于特定用途的任何默示保证。在不限制上述规定的前提下，{name} 特此声明，不保证在使用 {name} 平台时无错误、无漏洞或不间断。',
        ppH8_3: '8.3 在不影响第 5 条规定的情况下，任何一方不就下列任何一项在协议项下对另一方承担责任：(i) 利润、收入或销售损失；(ii) 交易损失；(iii) 机会损失；(iv) 替代服务的成本或其他经济损失，(v) 无法使用任何服务或任何计算机设备；(vi) 管理层或其他工作人员的时间损失；(vii) 专业费用或支出；(viii) 业务中断；(ix) 数据损坏或丢失；(x) 商誉或声誉损失，或 (xi) 在协议项下产生的任何类型或种类的任何间接、特殊、附带、惩戒性、惩罚性或后果性损害赔偿，即使已被告知发生该等损害赔偿的可能性，也不论是根据侵权（包括疏忽）、违约、严格责任还是其他原因提出的任何权利主张引起的。',
        ppH8_4: '8.4 在不影响第 5 条规定的情况下，在协议整个存续期内，因协议产生或与之相关的 {name} 的总责任不得超过客户在产生责任的首次事件发生前 12 个月内支付的总金额，以五万美元 (€50,000) 或当地货币等值金额为上限。在任何情况下, {name} 对协议项下的任何及所有索赔的总赔偿不得超过五万美元 (＄50,000) 或当地货币等值金额。',
        ppH8_5: '8.5 在适用法律允许的最大范围内，本第 8 条规定的责任限制和排除条款不适用于因一方的故意不当行为或重大过失造成的死亡或人身伤害。',
        ppH9: '9. 不可抗力',
        ppH9_1: '9.1 如果一方因不可抗力而无法履行协议项下的任何义务（支付本协议项下到期款项的义务除外），则该方可免予履行该等义务。就协议而言，“不可抗力”一词包括但不限于：阻碍协议正常履行的政府干预、罢工、疾病、大流行病、传染病、恐怖主义行为或威胁、业务中断、能源中断、第三方电信设施中断，以及一方无法合理避免或预防的所有其他情况。',
        ppH9_2: '9.2 如果一方因不可抗力而无法履行其义务，应在不可抗力发生之日起十 (10) 个工作日内向另一方告知相关情况。',
        ppH10: '10. 协议的期限、暂停和终止',
        ppH10_1: '10.1 协议的初始期限自生效日期开始，并在协议中规定的初始期限结束时到期（下称“初始期限”）。协议中未指定初始期限的，初始期限将从生效日期起一 (1) 年后到期。',
        ppH10_2: '10.2 初始期限届满后，协议将自动续期一 (1) 年（每个续期称为“续展期限”，与初始期限合称为“期限”），除非任何一方在当时的初始期限或续展期限结束之前提前三 (3) 个月向另一方发出书面通知终止协议。',
        ppH10_3: '10.3 如果客户严重违反协议的任何条款，{name} 有权暂停提供服务，而不另行通知，也不会限制 {name} 可享有的任何其他救济，包括解除协议和/或要求实际履行和/或损害赔偿。在任何该等暂停期间，客户仍有责任支付协议项下的任何到期应付费用。',
        ppH10_4: '10.4 一旦根据本条规定暂停提供服务的原因不复存在，{name} 应立即恢复服务提供，但 {name} 认为客户不合规行为的性质和/或频率足以导致永久取消服务提供的情况除外。',
        ppH10_5: '10.5 如果任何一方严重违反协议，且未能在收到书面违约通知后十 (10) 个工作日内纠正该违约行为，则另一方有权在向该方发出书面通知后终止协议。',
        ppH10_6: '10.6 在下列情况下，任何一方均可在向另一方发出书面通知后，进一步终止本协议：',
        ppH10_6_a: 'a. 另一方成为自愿或非自愿破产申请的对象，或成为与资不抵债、接管、清算或为债权人利益的和解相关的程序的对象；',
        ppH10_6_b: 'b. 第 9 条所述的不可抗力情况持续超过二十 (20) 个工作日；',
        ppH10_6_c: 'c. 监管机构要求实施该终止，或由于运营商条款和条件变更而需要实施该终止。',
        ppH10_7: '10.7 如果在发生第 10.5 条和第 10.6 条所述的终止时，客户已在协议履行期间获得任何提供的服务，则 {name} 在终止前就该等服务开具账单的任何金额应全部到期，并应在终止时立即支付。',
        ppH10_8: '10.8 按照性质预计在协议终止后仍然有效的规定应在协议到期后继续适用。该等规定包括但不限于协议的第 5 条（保证和赔偿）、第 6 条（保密和公开）、第 7 条（知识产权）、第 8 条（责任）、第 9 条（不可抗力）、第 10 条（协议的期限、暂停和终止）和第 12 条（适用法律和争议）的规定。',
        ppH11: '11. 权利和义务的转让；分包',
        ppH11_1: '11.1 未经另一方书面同意（不得无理拒绝做出该同意），任何一方不得全部或部分转让协议；但是任何一方均可在未经事先通知或允许的情况下，向其任何关联方或继承其与协议标的相关的全部或绝大部分业务和资产的第三方转让协议，无论是通过合并、收购、出售其多数股权、出售其绝大部分资产或类似交易的方式。凡是被禁止的转让均属无效。在不违反上述规定的前提下，协议应对继承人和获准受让人具有约束力，并以其为受益人。',
        ppH12: '12. 适用法律和争议',
        ppH12_1: '12.1 协议（以及因协议产生或与之相关的任何事项）应受《管辖法律和争议条款》中定义的适用州或国家/地区的法律管辖并据其解释，而不考虑会导致其他司法管辖区法律适用的法律和原则冲突。双方特此明确同意，《联合国国际货物销售合同公约》不适用。',
        ppH12_2: '12.2 凡因协议引起或与之相关的争议，包括关于协议存在、有效性或终止的问题，均应受《管辖法律和争议条款》中定义的管辖法院的专属管辖。',
        ppH13: '13. 其他规定',
        ppH13_1: '13.1 协议构成双方就协议标的达成的完整约定，并取代双方就协议标的达成的任何及所有先前或同期陈述、协定和约定。',
        ppH13_2: '13.2. 未能立即执行协议的某项规定，不得被解释为放弃该规定。协议中的任何内容均不得被视为或解释为在双方之间创建合资企业或合伙关系。',
        ppH13_3: '13.3. 任何一方均不得由于协议或其他原因被授权担任另一方的代理或法定代表。任何一方均未被授予任何权利或权限来代表另一方或以另一方的名义承担或设立任何明示或默示的义务或责任，或以任何方式约束另一方。协议的任何规定均不应被视为对任何第三方设立任何第三方受益权，但 {name} 的母公司和关联方将是协议特定条款的第三方受益人，并且该等条款（包括但不限于关于知识产权、赔偿、免责、仲裁、责任和弃权的条款）将适用于该等母公司和关联方。',
        ppH13_4: '13.4. 一经 {name} 请求，客户应提供 {name} 认为履行其报告义务所必需的任何合理要求的信息，包括《企业可持续性报告指令》规定的报告义务。{name} 无需声明重要性。客户应在二十 (20) 个工作日内提供所要求的信息，或就无法获得该等信息的原因以及预期可以获得该等信息的时间进行详细说明。客户应在合理可能的范围内，在其与第三方签订的协议中纳入类似条款，前提是可以合理预期可根据本条款的规定请求这些第三方提供信息。',
        ppH13_5: '13.5 各方确认，其有机会由其选择的独立法律顾问审查协议。如果协议的任何一条或多条规定被裁定为全部或部分无效或不可执行，则视为无效或不可执行的规定将被视为经过修订，并且法院或其他政府部门有权在使该等规定有效、可执行且符合协议所述的双方意图的最小必要范围内修改该等规定。',
        ppH13_6: '13.6 协议项下要求或允许的所有通知将采用书面形式，并提及协议。发送至 {name} 的所有通知应发送至相关 {name} 实体的地址，而发送给客户的所有通知均应发送至相关协议中载明的地址；或发送至相关方根据本第 13.8 条向另一方指定的其他地址。该等通知应在以下时间被视为送达：(i) 通过专人递送的，在递交时被视为送达；(ii) 通过全国认可的快递公司发送的，在投递后满一 (1) 个工作日并在收到书面接收确认时被视为送达；或 (iii) 通过预付邮资的挂号邮件或保证邮件（要求回执）发送的，在发出后满三 (3) 个工作日时被视为送达。',
        ppH13_7: '13.7 {name}有权随时修改协议及本《条款和条件》。如有重大修改，{name} 将告知客户。如果在发出修改通知之日起一个月内，客户未以书面形式提出异议，则视为客户已接受对《条款和条件》的修改。如果客户提出异议，则之前的《条款和条件》仍适用。但是，{name} 届时有权经提前一 (1) 个月发出书面通知终止与客户的协议。',
        ppH14: '14. 处理个人数据',
        ppH14_1: '14.1 {name} 可作为独立控制者，也可作为代表客户的处理方处理个人数据。{name} 代表客户处理个人数据的，DPA 应适用。{name} 作为控制者处理个人数据的，本第 14 条应适用。',
        ppH14_2: '14.2 在 {name} 处理涉及客户与 {name} 关系的个人数据（包括客户的员工或客户授权访问客户账户的人员的姓名和联系方式）或者客户尽职调查所需的个人数据时，{name} 决定处理的目标和方式，并且 {name} 有资格作为适用的数据保护法律项下的独立控制者。该等处理包括出于以下目的而处理的个人数据：账户和关系管理、与客户使用服务相关的营销、财务和计费数据、客户支持、信用检查、防止欺诈和犯罪活动、身份识别和“了解您的客户”(KYC) 流程。',
        ppH14_3: '14.3 在 {name} 作为公共电子通信服务提供商处理个人数据时，根据适用的电信法和适用的数据保护法律，对于处理电子通信服务运营所必需的个人数据，{name} 应作为独立控制者。该等处理包括出于流量、计费、欺诈、反垃圾邮件和合规目的处理的个人数据。',
        ppH14_4: '14.4 在根据上文第 14.2 和 14.3 条处理个人数据时，{name} 应遵守其在适用（数据保护）法律项下的义务。',
        ppH14_5: '14.5 尽管本第 14 条和/或 DPA 中有任何规定，{name} 有权收集、摘录、编译、合成和分析由客户使用或操作服务产生的非个人可识别数据或信息，包括但不限于与数量、频率、跳出率有关的信息，或客户、其最终用户或接收者使用服务产生的与使用服务有关的任何其他信息（“服务数据”）。如果 {name} 收集或生成任何服务数据，则该等数据将归 {name} 单独所有，{name} 可将其用于任何合法的内部或外部业务目的，无需向客户承担会计责任，前提是该等数据仅以汇总和去识别的形式使用。',
        noAccountRegisterNow: '未有账号,立即注册',
        welcomePlatform: '欢迎使用{name}平台',
        registerAccountUsePlatform: '注册账号使用平台',
        companyEmail: '公司电子邮箱',
        plsEnterCompanyEmail: '请输入公司电子邮箱',
        reenterPassword: '请再次输入密码',
        validPwdTips: '请输入合理的密码格式：大小写、数字、特殊符号的任意三种组合',
        agreePrivacyPolicy: '勾选则代表同意平台的条款声明及隐私协议',
        checkAgree: '勾选则代表同意平台的',
        platformTerms: '条款声明',
        and: '及',
        privacyPolicy: '隐私协议',
        alreadyLoginNow: '已有账号，立即登录',
        nextStep: '下一步',
        lastName: '姓',
        plsEnterLastName: '请输入姓',
        firstName: '名',
        plsEnterFirstName: '请输入名',
        position: '职务',
        plsEnterPosition: '请输入职务',
        contactNumber: '联系电话',
        plsEnterContactNumber: '请输入联系电话',
        previousStep: '上一步',
        skipStep: '跳过该步骤',
        registrationComplete: '注册完成',
        passwordsNotMatchReEnter: '两次输入的密码不同,请重新输入',
        plsEnterCompanyName: '请输入公司名称',
        checkAgreePrivacyPolicy: '请勾选,同意平台的条款声明及隐私协议!',
        registrationSuccessful: '注册成功',
        registrationFailed: '注册失败',
        invoiceConfirmation: '确认账单',
        unconfirmed: '未确认',
        confirmed: '已确认',
        confirmingInvoice: '您正在操作确认账单，一旦账单确认后表明您认可账单内容且系统',
        nolonger: '不再',
        supportsInvoiceChanges: '支持变更账单信息！！！',
        invoiceConfirmationSuccessful: '确认账单成功',
        accountBalance: '账户余额',
        availableCreditLimit: '可用/信控额度',
        topUp: '充值',
        topUpRefNo: '充值单号',
        transactionAmount: '交易金额',
        amountTransferred: '到账金额',
        succeed: '成功',
        fail: '失败',
        amountAfterConversionDisplayed: '自主充值的场景下会体现交易金额和交易币种以及换算后的结算币种及金额',
        transactionCost: '交易成本',
        transactionProcessWithoutRefundScenarios: '表示在交易过程中且不发生退款的场景下所有的成本数据',
        settlementCurrencyBasedRealTimeExchange: '表示在交易完成后按照实时汇率换算为结算币种充值进账户',
        topUpSucceed: '充值成功',
        rechargeSuccessful: '是否充值成功',
        rechargeType: '充值类型',
        selfServiceRecharge: '自助充值',
        manualRecharge: '手动充值',
        rechargeTime: '充值时间',
        definePaymentMethod: '定义付款方式',
        paymentInformation: '付款信息',
        rechargeFrequency: '充值频率',
        enterPaymentInformation: '填写付款信息',
        selectPaymentChannel: '选择付款渠道',
        enterChannelInformation: '录入渠道信息',
        OneTimePayment: '一次性付款',
        oneTimeNonRecurringPayment: '单独非循环式付款',
        rechargeCurrency: '充值币种',
        topUpAmout: '充值金额',
        plsEnterTopUpAmount: '请输入充值金额',
        plsSelectRechargeCurrency: '请选择充值币种',
        singleRechargeLimitBetween: '单次充值限制1~{name}之间，',
        rechargesAllowedPerDay: '每天仅允许充值{name}次',
        raisingSingleRechargeLimit: '若单次充值金额受限需提升单次充值额度，请邮件联系client@planet-alpha.net提升额度；',
        deductreceived: '扣除交易费用{name}，实际到账{name1}',
        plsSelectPaymentChannel: '请选择支付渠道',
        creditCardPayment: '信用卡支付',
        redirectedBankCompletePayment: '请输入您的信用卡或借记卡信息。如果您的卡片支持3DS身份验证，您会重新定向到发卡行完成支付',
        cardNumber: '卡号',
        expiryDate: '到期时间',
        plsSelectExpirationDate: '请选择到期时间',
        previousStep: '上一步',
        nextStep: '下一步',
        triggerThreshold: '触发阈值',
        enterTriggerThreshold: '请输入触发阈值',
        below: '低于',
        plsEnterCreditCardNumber: '请输入信用卡号',
        enterCVCCVV: '请输入CVC/CVV',
        rechargeFailed: '充值失败',
        numberManagement: '号码管理',
        plsEnterNumber: '请输入号码',
        purchaseTime: '购买时间',
        sourceNumber: '源号码',
        plsEnterSourceNumber: '请输入源号码',
        targetNumber: '目标号码',
        plsEnterTargetNumber: '请输入目标号码',
        receivedContent: '接收内容',
        receptionTime: '接收时间',
        todaysSentData: `今日发送数据`,
        intervalDataTransmission: '区间发送数据',
        custom: '自定义',
        today: '今天',
        smsRecord: '短信记录',
        plsSelectStatus: '请选择状态',
        submit: '提交',
        numberData: '号码数据',
        numberType: '号码类型',
        supportType: '支持类型',
        moFee: '上行费',
        mtFee: '下行费',
        appliedCountry: '应用国家',
        countryView: '国家维度',
        appliedView: '应用维度',
        everyMonth: '每个月',
        everyThreeMonths: '每三个月',
        everyYear: '每年',
        creditDebitCardInfo: '输入您的信用卡或借记卡信息。如果您的卡支持3DS身份验证，您会重定向到发卡行完成支付',
        MOManagement: '上行管理',
        receivingCodeManagement: '接码管理',
        purchasedNumber: '已购号码',
        monthlyRental: '月租',
        noCard: '无卡',
        availableCard: '有空闲卡',
        SIMCardRegistering: 'SIM 卡注册中',
        SIMRegisteredSuccessfully: 'SIM 注册成功',
        inCall: '正在通话',
        noBalanceAlert: '无余额或告警',
        registrationFailed: '注册失败',
        SIMLockedSystemAction: 'SIM 被锁定（程序行为）',
        SIMBlockedOoperatorAction: 'SIM 被封锁（运营商行为）',
        SIMReadError: 'SIM 读卡错',
        cardInserted: '卡已插入',
        userLocked: '用户锁定',

    }
}